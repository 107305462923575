import React, { useEffect } from "react";
import { inicializarFirebase, preguntarPermisos } from "./firebaseConfig";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Containers
import DefaultLayout from './components/DefaultLayout';



function App() {
    useEffect(() => {
        inicializarFirebase();
        
    }, []);
  return (
      <Router>
        <div className="App">
          <Switch>
              <Route path="/home" name="Home" component={DefaultLayout} />
              <Route path="/dashboard" name="dashboard" component={DefaultLayout} />
              <Route path="/" name="Index" component={DefaultLayout} />           
          </Switch> 
        </div>
      </Router>
  );
}

export default App;