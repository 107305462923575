import {FETCH_CUSTOMER} from './../constants/';
import { createAction } from 'redux-actions';


const initsessions = [
                  {
                  date: 'Lunes 1, Junio',
                  hours  : [
                      { group: 1, label: 'Grupo 9.30h', free: 0, wait: 0, user: 1 }, 
                      { group: 1, label: 'Grupo 11.30h', free: 0, wait: 0, user: 0 }, 
                      { group: 1, label: 'Grupo 17.30h', free: 0, wait: 0, user: 0 },
                      { group: 1, label: 'Grupo 18.30h', free: 0, wait: 0, user: 0 },
                      { group: 1, label: 'Grupo 19.30h', free: 0, wait: 0, user: 0 },
                      { group: 1, label: 'Grupo 20.30h', free: 0, wait: 0, user: 0 },  
                    ]
                  },
                  {
                  date: 'Martes 2, Junio',
                  hours  : [
                      { group: 2, label: 'Grupo 9.30h', free: 0, wait: 0, user: 0 }, 
                      { group: 2, label: 'Grupo 11.30h', free: 0, wait: 0, user: 0 }, 
                      { group: 2, label: 'Grupo 17.30h', free: 0, wait: 0, user: 0 },
                      { group: 2, label: 'Grupo 18.30h', free: 0, wait: 0, user: 0 },
                      { group: 2, label: 'Grupo 19.30h', free: 0, wait: 0, user: 0 },
                      { group: 2, label: 'Grupo 20.30h', free: 0, wait: 0, user: 0 },
                    ]
                  }, 
                  {
                  date: 'Miércoles 3, Junio',
                  hours  : [
                      { group: 3, label: 'Grupo 9.30h', free: 0, wait: 0, user: 1 }, 
                      { group: 3, label: 'Grupo 11.30h', free: 0, wait: 0, user: 0 }, 
                      { group: 3, label: 'Grupo 17.30h', free: 0, wait: 0, user: 0 },
                      { group: 3, label: 'Grupo 18.30h', free: 0, wait: 0, user: 0 },
                      { group: 3, label: 'Grupo 19.30h', free: 0, wait: 0, user: 0 },
                      { group: 3, label: 'Grupo 20.30h', free: 0, wait: 0, user: 0 }, 
                    ]
                  },
                  {
                  date: 'Jueves 4, Junio',
                  hours  : [
                      { group: 4, label: 'Grupo 9.30h', free: 0, wait: 0, user: 0 }, 
                      { group: 4, label: 'Grupo 11.30h', free: 0, wait: 0, user: 0 }, 
                      { group: 4, label: 'Grupo 17.30h', free: 0, wait: 0, user: 0 },
                      { group: 4, label: 'Grupo 18.30h', free: 0, wait: 0, user: 0 },
                      { group: 4, label: 'Grupo 19.30h', free: 0, wait: 0, user: 0 },
                      { group: 4, label: 'Grupo 20.30h', free: 0, wait: 0, user: 0 }, 
                    ]
                  },    
                  {
                  date: 'Viernes 5, Junio',
                  hours  : [
                    ]
                  },              
                ];

export const fetchCustomer = createAction(FETCH_CUSTOMER, () => initsessions);
