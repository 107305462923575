import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Snackbar from '@material-ui/core/Snackbar';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class User extends Component {

  constructor(props) {
    super();

    this.state = {
      user: [],
      items:[],
      done: true,
      message:'',
      openSnackBar:false,
      currentMonth:new Date().getMonth(),
      currentLevel:0,
      currentQuincena:0,
      currentName:'',
      currentLastName:'',
      currentBirth:'',
      currentNicename:'',
      currentPassw:'',
      currentNote:'',
      openOptionGroup:false,
      userOption:0,
      sessionOption:0,
      groupDayL:[],
      groupDayM:[],
      groupDayX:[],
      groupDayJ:[],
      groupDayV:[],
      groupAdd:0,
      groupDel:0,
      loading:false,
      openAddGroup:false,
      openDelGroup:false, 
      selected: {},     
    }
  }


  componentDidMount() {
    var user = localStorage.getItem('user');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

    if (userjs && userjs.level !== '10') 
      this.props.history.push('/');
   
  }

  //Creamos Usuario
  async apiCreateUser() {
        
        let name = this.state.currentName;
        let lastname = this.state.currentLastName;
        let birth = this.state.currentBirth;
        let nicename = this.state.currentNicename;
        let passw = this.state.currentPassw;
        let month = this.state.currentMonth;
        let level = this.state.level;
        let quincena = this.state.quincena;
        let note = this.state.currentNote;
        axios.post( URL_API + '/admin/createuser/', {
          secure_key: API_KEY,
          name: name,
          lastname: lastname,
          birth: birth,
          nicename: nicename,
          passw: passw,
          evaluacion: note,
        })
        .then(response => { 
          console.log(response.data);
          this.setState({ userOption: response.data.user});
          this.setState({ message: response.data.message});
          this.handleOpenSnackBar(); 
        })
        .catch(error => {
            console.log(error.response);

        });
    
  }


  handleOpenSnackBar () {
    this.setState({ openSnackBar: true});
  };

  render() {

    const handleOpenOptionGroup = (e) => {
        //this.apiCustomerGroups();
        this.setState({ openOptionGroup: true});  
    };  

    const handleCloseOptionGroup = (e) => {
      
     this.setState({ openOptionGroup: false});
    }; 

    const handleChangeName = (e) => {
      
        this.setState({currentName: e.target.value})
    }

    const handleChangeLastname = (e) => {
     
        this.setState({currentLastName: e.target.value})
    }

    const handleChangeBirth = (e) => {
      
        this.setState({currentBirth: e.target.value})
    }

    const handleChangePassw = (e) => {
     
        this.setState({currentPassw: e.target.value})
    }

    const handleChangeNicename = (e) => {
     
        this.setState({currentNicename: e.target.value})
    }

    const handleChangeNote = (e) => {
    
        this.setState({currentNote: e.target.value})
    }

    const handleClickSubmit = (e) => {
      
      this.apiCreateUser();
    }

  const handleCloseSnackBar = (event, reason) => {
    this.setState({ openSnackBar: false});
  };


    const handleChangeQuincena = (e) => {
     
        this.setState({currentQuincena: e.target.value})
    }

    const handleChangeLevel = (e) => {
     
        this.setState({currentLevel: e.target.value})
    }

    const handleChangeMonth = (e) => {
        
        this.setState({currentMonth: e.target.value})
    }


  const handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
    console.log('enviar form');
    console.log(this.state.selected);
    this.apiSetCustomerGroups();
  }

  var loading = '';
  if(this.state.loading === true){
    loading = <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>;
  }

  var button_crear = '';
  if(this.state.userOption <= 0){
    button_crear =  <Button variant="contained" color="primary" onClick={handleClickSubmit}>
                        Crear
                    </Button>;    
  }

  var button_group = '';
  if(this.state.userOption > 0){
    button_group = <Button variant="outlined" color="secondary" onClick={handleOpenOptionGroup}>
                        Completar ficha
                      </Button>;
  }

  return (

    <Grid container justify="center" >
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        
            <Grid 
                  item   
                  container
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <form className="userEdit" noValidate autoComplete="off">
                    <div className="wrapform">
                     <TextField id="firstname" 
                          label="Nombre" 
                          type="text" 
                          variant="filled" 
                          onChange={handleChangeName}
                          value={this.state.currentName} />
                     <TextField id="lastname" label="Apellidos" type="text" variant="filled" onChange={handleChangeLastname} value={this.state.currentLastName} />
                     <TextField id="birthday" label="Nacimiento" type="text" variant="filled" onChange={handleChangeBirth} value={this.state.currentBirth} />
                    
                     <TextField 
                        id="nicename" 
                        label="nicename" 
                        type="text" 
                        variant="filled"
                        onChange={handleChangeNicename} 
                        value={this.state.currentNicename}
                    />
                    <TextField 
                        id="passw" 
                        label="Contraseña"
                        onChange={handleChangePassw}
                        value={this.state.currentPassw}
                        type="text" 
                        variant="filled" 
                    />
                   
                    </div>
                    <div className="bottomform">
                      <TextField
                        id="outlined-multiline-static"
                        label="Evaluación"
                        multiline
                        rows={8}
                        onChange={handleChangeNote}
                        variant="outlined"
                      />
                    </div>
                    <div className="bottomform">
                      {button_crear}
                    </div>
                  </form>
                    <div >
                    {button_group}
                    </div>                  
            </Grid>

        )}
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.openSnackBar}
            autoHideDuration={4000}
            onClose={handleCloseSnackBar}
            message={this.state.message}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
           />



      <Dialog id="opyiondialog" fullScreen open={this.state.openOptionGroup} onClose={handleCloseOptionGroup} >
        <DialogContent>
          <UserDialog idUser={this.state.userOption} idSession={this.state.sessionOption} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionGroup} color="primary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
     </Grid>

      ); //return

  } //render

}

User.propTypes = {

}

export default withRouter(User);  