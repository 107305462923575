import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GroupIcon from '@material-ui/icons/Group';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class GroupsUsers extends Component {

  constructor() {
    super();
    this.state = {
      selectedDate: new Date(),
      users: [],
      originalUsers:[],
      order:'user_registered desc',
      filter:-1,
      userState:-1,
      message:'',
      openDialog:false,
      openModal:false,
      currentGroup: [],
      updatePlazas:null,
      done: false,
      updateUserName:'',
      userOption:''
    }
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

    if (userjs && userjs.level !== '10') 
      this.props.history.push('/');

   
    this.apiFectchUsers();
  }

  async apiFectchUsers(order = 'user_registered desc', filter = 0, userState = -1) {
       this.setState({ done: false});
      console.log(filter)
       
        axios.get( URL_API + '/admin/filter',
          {
              params: {
                 secure_key:API_KEY,
                 order:order,
                 filter:filter,
                 userstate:userState
              }
          })
        .then(response => { 
          console.log(response.data)
          if(response.data){
           this.setState({ users: response.data});
            this.setState({ originalUsers: response.data});
          }else{
            this.setState({ users: [{'firstname':'No encontrado', 'lastname':''}]});
          }
          this.setState({ done: true});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        }); 
  }

  render() {

  const handleChangeOrder = (event) => {
    this.setState({ filter: -1});
      this.setState({ order: event.target.value});
      this.apiFectchUsers(event.target.value);
  };

  const handleChangeFilter = (event) => {
     
      this.setState({ filter: event.target.value});
      if(event.target.value == -1){
        const search_items = this.state.originalUsers;
        this.setState({users:search_items});
      }else{
        var user_copy = this.state.originalUsers;
        const search_items = user_copy.filter(user => user.level === event.target.value);
        this.setState({users:search_items});        
      }

   

    //this.setState({ filter: event.target.value});
    //this.apiFectchUsers(this.state.order, event.target.value);
  };

  const handleChangeUserState = (event) => {
    this.setState({ filter: -1});
    this.setState({ userState: event.target.value});
    this.apiFectchUsers(this.state.order, this.state.filter, event.target.value);
  };

  const handleChangeTab = (event) => {

  };
  //EDITAR USUARIO
  const handleUserEdit = (e) => {
    
    //this.props.history.push('/dashboard/user/'+ this.state.updateUserId);
  };

  const handleOpenOption = (e) => {
      var user_split = e.target.id.split("-");
      console.log(user_split)
      if(user_split && user_split[1]){
        this.setState({ userOption: user_split[1]});

        this.setState({ openOption: true});       
      }
  };  

  const handleCloseOption = (e) => {
    
   this.setState({ openOption: false});
  };  

  const handleActiveUser = (event, reason) => {
      localStorage.setItem('user_edit', this.state.updateUserId );
      this.props.history.push('/home/');
  };

  const users = this.state.users;
  return (
  <div>
    <Grid className="topdashboard" container justify="center">
        <Grid item xs={4}>    
          <Select
          className="selectOrderMat"
            value={this.state.order}
            onChange={handleChangeOrder}
            
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value='user_registered desc'>Fecha Alta</MenuItem>
            <MenuItem value='firstname'>Nombre</MenuItem>
            <MenuItem value='lastname'>Apellidos</MenuItem>
          </Select>
         
        </Grid>
        <Grid item xs={4}>    
          <Select
          className="selectUserStateMat"
            value={this.state.userState}
            onChange={handleChangeUserState}
           
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={-1}>Estado</MenuItem>
            <MenuItem value={1}>Activo</MenuItem>
            <MenuItem value={0}>Baja Tem</MenuItem>
            <MenuItem value={2}>No Activos</MenuItem>
          </Select>
         
        </Grid>
        <Grid item xs={4}>    
          <Select
          className="selectFilterMat"
            value={this.state.filter}
            onChange={handleChangeFilter}
         
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={-1}>Grupo</MenuItem>
            <MenuItem value='M'>Mixtos</MenuItem>
            <MenuItem value='P'>Pilates</MenuItem>
            <MenuItem value='F'>Funcional</MenuItem>
            <MenuItem value='Y'>Yoga</MenuItem>
            <MenuItem value='S'>S.4 / S.8</MenuItem>
            <MenuItem value='E'>Especial</MenuItem>
            <MenuItem value='V'>Vip</MenuItem>
            <MenuItem value='0'>Sin Grupo</MenuItem>
          </Select>        
        </Grid>

    </Grid>
    <Grid id="adminPilates" container justify="center">
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        <Grid item xs={12} sm={12} md={6}>
          <div className="matriculas">
            <List dense={true}>
                <ListItem key={-1} dense>
                  <ListItemText  primary={`Registros ${this.state.users.length}`} />
                </ListItem>
              {users.map((user, idx) => {  
                var groups = user.groups
                var str_groups = groups.map(function(group) {
                  var array_day =['D','L','M','X','J','V','S'];
                  var day_label = array_day[group.day_group] + group.hour_group;
                    return (<Chip size="small" className={`grouptype_${group.type}`} label={day_label} />);
                  
                });
                                   
                  return(
                             
                    <ListItem key={idx} className={`userActive_${user.state}`}>
                      <ListItemText className="item-text__left"
                        primary={`${user.firstname} ${user.lastname}`}
                        secondary={
                          <React.Fragment>
                            <div>
                              {str_groups}
                            </div>
                          </React.Fragment>
                        }
                      />
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <div className="flex">
                             <Avatar className="avinfo avlevel">{user.level}</Avatar>
                              <Avatar className={`avinfo quincena_${user.quincena}`}>{user.quincena}</Avatar>
                            </div>
                          </React.Fragment>
                        }
                        secondary={user.fecha }
                      />
                      
                     
                      <ListItemSecondaryAction >
                        <IconButton edge="end" aria-label="more">
                          <MoreVertIcon id={`id-${user.user_id}`}  onClick={handleOpenOption} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                  ); // return inner
              })}
              
            </List>
           </div>
        </Grid>
        )}

      <Dialog id="opyiondialog" fullScreen
          open={this.state.openOption} onClose={handleCloseOption} >
        <DialogContent>
          <UserDialog idUser={this.state.userOption} idSession={-1} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOption} color="primary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


     </Grid>
    </div>
      ); //return



  } //render

}

GroupsUsers.propTypes = {

}

export default GroupsUsers;  