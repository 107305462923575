import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupIcon from '@material-ui/icons/Group';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserDialog from '../../components/UserDialog';

class Groups extends Component {

  constructor(props) {
    super();
    this.routeParam = props.match.params.pantalla;
    this.type = this.routeParam.charAt(0).toUpperCase();
    this.state = {
      selectedDate: new Date(),
      sessions: [],
      message:'',
      openSnackBar:false,
      openDialog:false,
      openDialogNew:false,
      openModalOption:false,
      openActionReplica:false,
      openActionRevisar:false,
      openActionActivar:false,
      userOption:0,
      sessionOption:-1,
      currentGroup: [],
      updatePlazas:null,
      done: false,
      newDay:'',
      newHour:'',
      newMinutes:'',
      newPlazas:'',
      updateUserName:'',
      updateUserId:'',
      currentMonth:new Date().getMonth(),
      currentYear:new Date().getFullYear(),
    }
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

    if (userjs && userjs.level !== '10') 
      this.props.history.push('/');

   
    this.apiFectchGroups(this.state.currentMonth,this.state.currentYear);
  }

  async apiFectchGroups(month = 0, year = 0) {
    
       var select_month = '';
        
          select_month = month + 1;
           
       var select_year = '';
       
          select_year = year;
       
        let user_id = this.state.user_id;

        axios.get(URL_API + '/usersbygroup',
          {
              params: {
                 secure_key:API_KEY,
                 type:this.type,
                 month: select_month, 
                 year: select_year,
              }
          })
        .then(response => { 
          console.log(response.data)
          this.setState({ sessions: response.data});
           this.setState({ done: true});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });  
  }

  async apiUpdateGroup() {
        var id_group = this.state.currentGroup.group;
        var num_plazas = this.state.updatePlazas;
        console.log(id_group);
        console.log(num_plazas);
        axios.post(URL_API + '/group/update/',
          {
              secure_key:API_KEY,
              id_group:id_group,
              num_plazas: num_plazas
          })
        .then(response => { 
          console.log(response.data);
           if(response.data.group_update){
                this.apiFectchGroups();
            }
        })
        .catch(error => {
            console.log(error.response);
        });  
  }

  async apiCreateGroup() {
        var day = this.state.newDay;
        var hour = this.state.newHour + ':' + this.state.newMinutes;
        var num_plazas = this.state.newPlazas;
        axios.post(URL_API + '/group/create/',
          {
              secure_key:API_KEY,
              day:day,
              hour:hour,
              plazas: num_plazas,
              type:this.type,
          })
        .then(response => { 
          console.log(response.data);
           if(response.data.action){
                this.apiFectchGroups();
            }
        })
        .catch(error => {
            console.log(error.response);
        });  
  }

  async apiReplicateGroups(month=0, year=0) {
      var select_month = '';
      var select_year = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }       
      
        if(year !== 0){
          select_year = year + 1;
        }else{
          select_year = this.state.currentYear;
        }        
       console.log(select_month);
        axios.post(URL_API + '/admin/groups/replicate',
          {
              secure_key:API_KEY,
              month:select_month,
              year:select_year,
              type:this.type,
          })
        .then(response => { 
          console.log(response.data);
           if(response.data){
                this.setState({ message: response.data.message});
                this.handleOpenSnackBar();            
                this.apiFectchGroups();
                this.setState({ done: true});
            }
        })
        .catch(error => {
            console.log(error.response);
        });  
  }

  async apiAssignGroups(month=0, year= 0) {
      var select_month = '';
      var select_year = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }       
      
        if(year !== 0){
          select_year = year + 1;
        }else{
          select_year = this.state.currentYear;
        }        
       
       console.log(select_month);
        axios.post(URL_API + '/admin/groups/assign',
          {
              secure_key:API_KEY,
              month:select_month,
              year:select_year,
              type:this.type,
          })
        .then(response => { 
          console.log(response.data);
           if(response.data){
                this.setState({ message: response.data.message});
                this.handleOpenSnackBar();            
                //this.apiFectchGroups();
                this.setState({ done: true});
            }
        })
        .catch(error => {
            console.log(error.response);
        });  
  }

  async apiActivateGroups(month=0, year= 0) {
      var select_month = '';
      var select_year = '';
        if(month !== 0){
          select_month = month + 1;
        }else{
          select_month = this.state.currentMonth + 1;
        }       
      
        if(year !== 0){
          select_year = year + 1;
        }else{
          select_year = this.state.currentYear;
        }        
       
       console.log(select_month);
        axios.post(URL_API + '/admin/groups/activate',
          {
              secure_key:API_KEY,
              month:select_month,
              year:select_year,
              type:this.type,
          })
        .then(response => { 
          console.log(response.data);
           if(response.data){
                this.setState({ message: response.data.message});
                this.handleOpenSnackBar();            
                //this.apiFectchGroups();
                this.setState({ done: true});
            }
        })
        .catch(error => {
            console.log(error.response);
        });  
  }

  handleOpenSnackBar () {
    this.setState({ openSnackBar: true});
  };

  render() {
  const handleGroupEdit = (event) => {
    let group = event.target.parentElement.id.split("_");
    const currentGroup = this.state.sessions[group[1]];
    this.setState({ currentGroup: currentGroup});
    this.setState({ updatePlazas: currentGroup.plazas});
    handleOpenDialog();
  }

  const handleOpenDialog = () => {

      this.setState({ openDialog: true});
  };

  const handleAcceptDialog = (event, reason) => {
    this.apiUpdateGroup();
    this.setState({ openDialog: false});
  };

  const handleCloseDialog = (event, reason) => {
      this.setState({ openDialog: false});
  };

  const handleOpenDialogNew = () => {

      this.setState({ openDialogNew: true});
  };

  const handleAcceptDialogNew = (event, reason) => {
    
    this.apiCreateGroup();
    this.setState({ openDialogNew: false});
  };

  const handleCloseDialogNew = (event, reason) => {
      this.setState({ openDialogNew: false});
  };

  const handleChangePlazas = (event) => {
    this.setState({ updatePlazas: event.target.value});

  }

  const hadleSelectDay = (event) => {
      console.log(event.target.value);
      this.setState({ newDay: event.target.value});
  }

  const hadleSelectHour = (event) => {
      console.log(event.target.value);
      this.setState({ newHour: event.target.value});
  }

  const hadleSelectMinutes = (event) => {
      console.log(event.target.value);
      this.setState({ newMinutes: event.target.value});
  }

  const hadleTextPlazas = (event) => {
      console.log(event.target.value);
      this.setState({ newPlazas: event.target.value});
  }


  const handleOpenModalOption = (event) => {
    var user_split = event.target.id.split("-");
     console.log(user_split[1]);

      if(user_split && user_split[1]){
        this.setState({ updateUserName: user_split[2] + ' ' +user_split[3]});
        this.setState({ updateUserId: user_split[1]});
        this.setState({ openModalOption: true});       
      }
  }

  const handleCloseModalOption = (event, reason) => {
      this.setState({ openModalOption: false});
  };

  const handleActiveUser = (event, reason) => {
      localStorage.setItem('user_edit', this.state.updateUserId );
      this.props.history.push('/home/');
  };

  const handleChangeMonth = (e) => {
        this.setState({ done: false});
        this.setState({currentMonth: e.target.value});
        
        this.apiFectchGroups(e.target.value,this.state.currentYear);
  }

  const handleChangeYear = (e) => {
        this.setState({ done: false});
        this.setState({currentYear: e.target.value});
        
        this.apiFectchGroups(this.state.currentMonth,e.target.value);
  }

  const handleClickReplica = (e) => {
        this.setState({ openActionReplica: true});
        
  }

  const handleAcceptModalActionReplica = (e) => {
        this.setState({ done: false});
        this.apiReplicateGroups();
        this.setState({ openActionReplica: false});
  }

  const handleCloseModalActionReplica = (e) => {
      this.setState({ openActionReplica: false});
  }

  const handleClickRevision = (e) => {
      this.setState({ openActionRevisar: true});
  }

  const handleAcceptModalActionRevision = (e) => {
       this.setState({ done: false});
        this.apiAssignGroups();
        this.setState({ openActionRevisar: false});
  }

  const handleCloseModalActionRevision = (e) => {
      this.setState({ openActionRevisar: false});
  }

  const handleClickActive = (e) => {
      this.setState({ openActionActivar: true});
  }

  const handleAcceptModalActionActive = (e) => {
       this.setState({ done: false});
        this.apiActivateGroups();
        this.setState({ openActionActivar: false});
  }

  const handleCloseModalActionActive = (e) => {
      this.setState({ openActionActivar: false});
  }

  const handleCloseSnackBar = (event, reason) => {
    this.setState({ openSnackBar: false});
  };

  const groups = this.state.sessions;
  return (

    <Grid id={`admin${this.routeParam}`} container justify="center" >

        <Grid item xs={12} sm={12} md={12}>
          <div className={`header-section header-section__${this.routeParam}`}>
            {`GRUPOS ${this.routeParam}`}                 
          </div>
        </Grid>
        <Grid container justify="center" className="topuser">
              <Paper component="form" className="topinformer">
                      <Select
                        labelId="select-month"
                        id="month-select"
                        value={this.state.currentMonth}
                        onChange={handleChangeMonth}
                      >
                        <MenuItem value={0}>Enero</MenuItem>
                        <MenuItem value={1}>Febrero</MenuItem>
                        <MenuItem value={2}>Marzo</MenuItem>
                        <MenuItem value={3}>Abril</MenuItem>
                        <MenuItem value={4}>Mayo</MenuItem>
                        <MenuItem value={5}>Junio</MenuItem>
                        <MenuItem value={6}>Julio</MenuItem>
                        <MenuItem value={7}>Agosto</MenuItem>
                        <MenuItem value={8}>Septiembre</MenuItem>
                        <MenuItem value={9}>Octubre</MenuItem>
                        <MenuItem value={10}>Noviembre</MenuItem>
                        <MenuItem value={11}>Diciembre</MenuItem>
                      </Select>                    
                     
                        <Select
                          labelId="select-year"
                          id="year-select"
                          value={this.state.currentYear}
                          onChange={handleChangeYear}
                        >
                          <MenuItem value={2020}>2020</MenuItem>
                          <MenuItem value={2021}>2021</MenuItem>
                          <MenuItem value={2022}>2022</MenuItem>
                          <MenuItem value={2023}>2023</MenuItem>
                          <MenuItem value={2024}>2024</MenuItem>
                        </Select> 
                    
                      <Button size="small" variant="contained" color="secondary" onClick={handleClickReplica}>
                        Rep
                      </Button>                       
                      <Button size="small" variant="contained" color="primary" onClick={handleClickRevision}>
                        Rev
                      </Button>                      
                      <Button size="small" variant="contained" color="defaut" onClick={handleClickActive}>
                        Act
                      </Button>                       
            </Paper>
        </Grid>
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        <Grid item xs={12} sm={12} md={6}>

          {groups.map((session, indx) => {
              const numRows = session.users.length
              return(        
                   
                    <List key={indx} dense className={`group_active_${session.active}`}>
                    <div className="titleList">           
                      <div>{session.date}</div> <GroupWorkIcon /><div>{session.plazas}</div>   <GroupIcon /><div>{numRows}</div>
                      <div>
                      <Button size="small" color="inherit" className="editGroup" id={`id_${indx}`} onClick={handleGroupEdit}>
                        edit
                      </Button>
                      </div>
                    </div>
                      {session.users.map((value, idx) => {
                        const labelId = `checkbox-list-label-${value.label}`;
                       
                          return (
                            <ListItem key={idx} dense>
                             
                              <ListItemText id={labelId} primary={ `${value.firstname} ${value.lastname}` } />

                              <ListItemSecondaryAction>
                               <span className={`quincena_${value.group_quincena}`}>{ value.group_quincena !== 'Q0' ? value.group_quincena : '' }</span> &nbsp;&nbsp;
                               { value.group_level }
                                  <IconButton edge="end" aria-label="edit">
                                    <MoreVertIcon id={`euser-${value.id}`} onClick={handleOpenModalOption} />
                                  </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );

                      })}                     
                    </List>
              ); // return inner
          })}
        </Grid>
        )}
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={this.state.openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="edit-dialog">{`${this.state.currentGroup.date}`}</DialogTitle>
        <DialogContent>
                <form id="edit-group">
                    <FormControl component="fieldset" fullWidth="true">
                      <TextField
                        id="plazas"
                        label="Plazas"
                        onChange={handleChangePlazas}
                        type="number"
                        defaultValue={this.state.currentGroup.plazas}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                </form>          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAcceptDialog} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={this.state.openDialogNew}
        onClose={handleCloseDialogNew}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="new-dialog">EDITAR GRUPO</DialogTitle>
        <DialogContent>
          <form id="new-group">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth="true">
                <InputLabel id="label-day">Día</InputLabel>
                <Select
                    labelId="day-select"
                    id="day-select"
                    onChange={hadleSelectDay}
                  >
                    <MenuItem value={1}>Lunes</MenuItem>
                    <MenuItem value={2}>Martes</MenuItem>
                    <MenuItem value={3}>Miércoles</MenuItem>
                    <MenuItem value={4}>Jueves</MenuItem>
                    <MenuItem value={5}>Viernes</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl component="fieldset" fullWidth="true">
                <InputLabel>Hora</InputLabel>
                <Select
                  labelId="hour-select"
                  id="hour-select"
                  onChange={hadleSelectHour}
                  >
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                  <MenuItem value={13}>13</MenuItem>
                  <MenuItem value={14}>14</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={16}>16</MenuItem>
                  <MenuItem value={17}>17</MenuItem>
                  <MenuItem value={18}>18</MenuItem>
                  <MenuItem value={19}>19</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={21}>21</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset" fullWidth="true">
                <InputLabel>Minutes</InputLabel>
                  <Select
                    labelId="minutes-select"
                    id="minutes-select"
                    onChange={hadleSelectMinutes}
                     >
                    <MenuItem selected value={'30'}>30</MenuItem>
                    <MenuItem value={'00'}>00</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
               <TextField
                  id="num-plazas"
                  label="Plazas"
                  onChange={hadleTextPlazas}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
          </Grid>
          </form>        
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialogNew} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAcceptDialogNew} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog id="fulldialog" fullScreen open={this.state.openModalOption} onClose={handleCloseModalOption} >
        <DialogContent className="fulldialog_content">
             <UserDialog idUser={this.state.updateUserId} idSession={-1} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModalOption} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={this.state.openActionReplica}
        onClose={handleCloseModalActionReplica}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Copiar grupos del mes actual al siguiente mes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Quieres copiar los grupos y sus usuarios del mes actual al siguiente mes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalActionReplica} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalActionReplica} color="primary">
            aceptar
          </Button>
        </DialogActions>
      </Dialog>

     <Dialog
        open={this.state.openActionRevisar}
        onClose={handleCloseModalActionRevision}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Asignar las clases a los usuarios</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Quieres asignar para que los usuarios revisen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalActionRevision} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalActionRevision} color="primary">
            aceptar
          </Button>
        </DialogActions>
      </Dialog>

     <Dialog
        open={this.state.openActionActivar}
        onClose={handleCloseModalActionActive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Activar las clases a los usuarios</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Quieres activar y hacer visible las clases a los usuarios?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalActionActive} color="primary">
            cancelar
          </Button>
          <Button onClick={handleAcceptModalActionActive} color="primary">
            aceptar
          </Button>
        </DialogActions>
      </Dialog>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.openSnackBar}
            autoHideDuration={4000}
            onClose={handleCloseSnackBar}
            message={this.state.message}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
           />

     </Grid>

      ); //return



  } //render

}

Groups.propTypes = {

}

export default withRouter(Groups);  