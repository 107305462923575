import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class Birthday extends Component {

  constructor() {
    super();

    this.state = {
      currentMonth:-1,
      currentType:0,
      order:0,
      done: false,
      items: [],
      originalItems:[],
      userOption:'',
      openOption:'',
      sessionOption:'',
      routeParam:'',
    }

   // Bind the this context to the handler function
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

    if (userjs && userjs.level !== '10') 
      this.props.history.push('/');

   
    this.apiUsersBirthday();
  }

  handleUpdate = (e) => {
    
   this.apiInformeUsers(this.state.currentMonth);
    this.setState({ openOption: false});
  }; 

  async apiUsersBirthday(month = '') {
                
    if(month === ''){
      month = this.state.currentMonth;
    }

    month = month + 1;
    console.log(month);
    axios.get( URL_API + '/users/birthday',
      {
          params: {
             secure_key:API_KEY,
             month: month,
          }
      })
    .then(response => { 
      console.log(response.data);
      this.setState({ done: true});
      this.setState({ items: response.data});
    })
    .catch(error => {
        console.log(error.response);
    });
        
  }

  render() {

const handleChangeMonth = (event) => {
  
  this.setState({ currentMonth: event.target.value });
  this.setState({ done: false});
  this.apiUsersBirthday(event.target.value);
};



  const handleChangeOrder = (event) => {
   		
      this.setState({ order: event.target.value});
      console.log(event.target.value);
      if(event.target.value === 'libres'){
      	console.log(this.state.items.sort((a,b) =>  b.libres-a.libres ))
      }
      if(event.target.value === 'faltas'){
      	console.log(this.state.items.sort((a,b) =>  b.faltas-a.faltas ))
      }  
      if(event.target.value === 'apuntadas'){
        console.log(this.state.items.sort((a,b) =>  b.asiste-a.asiste ))
      }       
  };

  const handleOpenOption = (e) => {
      var user_split = e.target.id.split("-");
      if(user_split && user_split[1]){
        this.setState({ userOption: user_split[1]});
        this.setState({ sessionOption: user_split[2]});
        this.setState({ openOption: true});       
      }

  };  

  const handleCloseOption = (e) => {
    
   this.setState({ openOption: false});
  }; 

  const handleChangeSearch = (e) => {
    
    console.log(e.target.value.length);
    if(e.target.value.length > 0){
      this.setState({ routeParam: e.target.value});
      const regex = new RegExp("^" + e.target.value, "i");
      const search_items = this.state.items.filter(user => user.firstname.match(regex));
      this.setState({items:search_items});
    }else{
      this.setState({items:this.state.originalItems});
    }

           
  } 

  const handleListSearch = (e) => {
    var query = document.getElementById("query").value;

 
  }

  return (

    <Grid id="adminformes" container justify="center">

        <Grid item xs={12} className="topdashboard" container justify="center">
            
              <Grid item xs={6}>
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentMonth}
                  onChange={handleChangeMonth}
                >
                  <MenuItem value={-1}>Mes</MenuItem>
                  <MenuItem value={0}>Enero</MenuItem>
                  <MenuItem value={1}>Febrero</MenuItem>
                  <MenuItem value={2}>Marzo</MenuItem>
                  <MenuItem value={3}>Abril</MenuItem>
                  <MenuItem value={4}>Mayo</MenuItem>
                  <MenuItem value={5}>Junio</MenuItem>
                  <MenuItem value={6}>Julio</MenuItem>
                  <MenuItem value={7}>Agosto</MenuItem>
                  <MenuItem value={8}>Septiembre</MenuItem>
                  <MenuItem value={9}>Octubre</MenuItem>
                  <MenuItem value={10}>Noviembre</MenuItem>
                  <MenuItem value={11}>Diciembre</MenuItem>
                </Select>
               </Grid>
               <Grid item xs={6}>
                  {`Registros ${this.state.items.length}`}              
               </Grid>

              
      </Grid>            
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        <Grid item xs={12} sm={12} md={6}>
          <div className="matriculas">
            <List dense={false}>
                {this.state.items.map((user, indx) => {

                  return( 
                    <ListItem key={indx} dense>

                    <ListItemText  primary={`${user.firstname} ${user.lastname}`}

                     />
                     &nbsp;
                      <Typography variant="caption" display="block" gutterBottom>
                        {user.birthday}
                      </Typography>                    

                      <ListItemSecondaryAction >
                        <IconButton edge="end" aria-label="more" >
                          <MoreVertIcon id={`id-${user.user_id}`}  onClick={handleOpenOption} />
                        </IconButton>
                      </ListItemSecondaryAction>                    
                    </ListItem>

                  );
  
                })}
            </List>
           </div>
        </Grid>
        )}
      <Dialog id="opyiondialog" fullScreen
          open={this.state.openOption} onClose={handleCloseOption} >
        <DialogContent>
          <UserDialog idUser={this.state.userOption} idSession={-1} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOption} color="primary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
     </Grid>

      ); //return



  } //render

}

Birthday.propTypes = {

}

export default Birthday;  