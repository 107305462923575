import firebase from "firebase";

export const inicializarFirebase = () => {
    firebase.initializeApp({
      apiKey: "AIzaSyCHaiL2CLgByeiBQtGyySfRmzHt66BtMp8",
      authDomain: "cuerpopleno-d6109.firebaseapp.com",
      databaseURL: "https://cuerpopleno-d6109.firebaseio.com",
      projectId: "cuerpopleno-d6109",
      storageBucket: "cuerpopleno-d6109.appspot.com",
      messagingSenderId: "12047192337",
      appId: "1:12047192337:web:8d686bb70e9ed2d03bbcb0"
    });


  // use other service worker
  // navigator.serviceWorker
  //   .register('/my-sw.js')
  //   .then((registration) => {
  //     firebase.messaging().useServiceWorker(registration);
  //   });
};

export const preguntarPermisos = async () => {
    try {
        const messaging = firebase.messaging();

        //await messaging.requestPermission();
        await Notification.requestPermission().then(async permission => {
            if (permission === "denied") {
                console.log("Permission wasn't granted. Allow a retry.");
                return;
            } else if (permission === "default") {
                console.log("The permission request was dismissed.");
                return;
            }
            const token = await messaging.getToken();
            console.log("user token: ", token);
            localStorage.setItem('token', token );
            return token;
        });
    } catch (error) {
        console.error(error);
    }
};