import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import AppHeader from './AppHeader';
import MainList from '../views/Sessions/MainList';
import AdminList from '../views/Dashboard/AdminList';
import AdminSearch from '../views/Dashboard/AdminSearch';
import Groups from '../views/Dashboard/Groups';
import Informes from '../views/Dashboard/Informes';
import Birthday from '../views/Dashboard/Birthday';
import Contabilidad from '../views/Dashboard/Contabilidad';
import Calendarios from '../views/Dashboard/Calendarios';
import Users from '../views/Dashboard/Users';
import User from '../views/Dashboard/User';
import Perfil from '../views/Pages/Perfil';
import PerfilSessions from '../views/Pages/Sessions';
import PerfilNotifications from '../views/Pages/Notifications';
import Home from '../views/Pages/Home';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    position:'relative',
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  offset: theme.mixins.toolbar
}));



const DefaultLayout = props => {
  const classes = useStyles();

	return (
      <div className={classes.root}>
        <AppHeader></AppHeader>
        <div className={classes.offset}></div>
             <Route exact path="/home/sessions/:pantalla" name="Sessions List" component={MainList} />
              <Route exact path="/home/perfil/sessions" name="List Sessions" component={PerfilSessions} /> 
             <Route exact path="/home/perfil/notifications" name="List Notification" component={PerfilNotifications} /> 
             <Route exact path="/home/perfil" name="Sessions Funcional" component={Perfil} />
             <Route exact path="/dashboard/birthday" name="Dashboard" component={Birthday} />
             <Route exact path="/dashboard/contabilidad" name="Dashboard" component={Contabilidad} />
             <Route exact path="/dashboard/calendarios" name="Dashboard" component={Calendarios} /> 
             <Route exact path="/dashboard/informes" name="Dashboard" component={Informes} />   
             <Route exact path="/dashboard/users" name="Dashboard" component={Users} /> 
             <Route exact path="/dashboard/user/:id" name="Dashboard" component={User} /> 
             <Route exact path="/dashboard/search/:query" name="Dashboard" component={AdminSearch} /> 
             <Route exact path="/dashboard/groups/:pantalla" name="Dashboard" component={Groups} />
             <Route exact path="/dashboard" name="Dashboard" component={AdminList} />  
             <Route exact path="/home" name="Home" component={Home} />
             <Route exact path="/" name="Index" component={Home} />       
           
      </div>
	);



}


DefaultLayout.propTypes = {

}

export default DefaultLayout;
