import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupIcon from '@material-ui/icons/Group';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class AdminSearch extends Component {

  constructor(props) {
    super();

    this.state = {
      users: [],
      done: false,
      message:'',
      openDialog:false,
      openOption:false,
      openDialogNew:false,
      currentGroup: [],
      updatePlazas:null,
      done: false,
      newDay:'',
      newHour:'',
      newMinutes:'',
      newPlazas:'',
      updateUserName:'',
      userOption:false,
      routeParam:'',
      type:''
    }
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

    if (userjs && userjs.level !== '10') 
      this.props.history.push('/');
   
    this.setState({ routeParam: this.props.match.params.query});
    this.setState({ type: this.props.match.params.query.charAt(0).toUpperCase()});
    this.apiSearchCustomer(this.props.match.params.query);
    
  }

  async apiSearchCustomer(query = '') {
       this.setState({ done: false});
  
        console.log(query);
        axios.get( URL_API + '/admin/search',
          {
              params: {
                 secure_key:API_KEY,
                 query:query
              }
          })
        .then(response => { 
          console.log(response.data)
          if(response.data){
           this.setState({ users: response.data});
          
          }else{
            this.setState({ users: [{'firstname':'No encontrado', 'lastname':''}]});
          }
          this.setState({ done: true});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });    

  }

  render() {

  const handleChangeSearch = (e) => {
   
    this.setState({ routeParam: e.target.value});
  } 

  const handleListSearch = (e) => {
    var query = document.getElementById("query").value;
    if(query.length > 0){
      this.setState({ routeParam: query});
      query = query.replace(' ', '-');
      this.props.history.push('/dashboard/search/' + query);
      
      this.setState({ done: false});
      this.apiSearchCustomer(query);     
    }else{
      this.props.history.push('/dashboard');
    }
 
  } 


  const handleOpenOption = (e) => {

      var user_split = e.target.id.split("-");
      if(user_split && user_split[1]){
        this.setState({ userOption: user_split[1]});
       
        this.setState({ openOption: true});       
      }
  };  

  const handleCloseOption = (e) => {
    
   this.setState({ openOption: false});
  };  

  const handleActiveUser = (event, reason) => {
      localStorage.setItem('user_edit', this.state.updateUserId );
      this.props.history.push('/home/');
  };

  const users = this.state.users;

  return (

    <Grid container justify="center" >
        <Grid item xs={12}>
          <Paper component="form" className="topadminlist"  onSubmit={handleListSearch}>
             
                <InputBase
                  className="search"
                  id="query"
                  value={this.state.routeParam}
                  onChange={handleChangeSearch}
                  inputProps={{ 'aria-label': 'buscar...' }}
                />
                <IconButton type="button" className="btnsearch" aria-label="search" onClick={handleListSearch}>
                  <SearchIcon />
                </IconButton>
             
          </Paper> 
        </Grid>
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        
        <Grid item xs={12} >
          <div className="listsearch">
            <List dense={true}>
           
              {users.map((user, idx) => {  
                var groups = user.groups
                var str_groups = groups.map(function(group) {
                  var array_day =['D','L','M','X','J','V','S'];
                  var day_label = array_day[group.day_group] + group.hour_group;
                    return (<Chip size="small" className={`grouptype_${group.type}`} label={day_label} />);
                  
                });
                                   
                  return(
                             
                    <ListItem key={idx} className={`userActive_${user.state} userpay_${user.pay}`} >
                      <ListItemText
                        primary={`${user.firstname} ${user.lastname}`}
                        secondary={
                          <React.Fragment>
                            <div>
                              {str_groups}
                            </div>
                          </React.Fragment>
                        }
                      />
                      <Avatar className="avinfo avlevel">{user.level}</Avatar>
                      <Avatar className={`avinfo quincena_${user.quincena}`}>{user.quincena}</Avatar>
                      <ListItemSecondaryAction >
                        <IconButton edge="end" aria-label="more">
                          <MoreVertIcon id={`cid-${user.user_id}`} onClick={handleOpenOption}/>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                  ); // return inner
              })}
              
            </List>
          </div>
        </Grid>
        )}

      <Dialog id="opyiondialog" fullScreen
          open={this.state.openOption} onClose={handleCloseOption} >
        <DialogContent>
          <UserDialog idUser={this.state.userOption} idSession={-1} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOption} color="primary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

     </Grid>

      ); //return



  } //render

}

AdminSearch.propTypes = {

}

export default withRouter(AdminSearch);  