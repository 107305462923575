import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { URL_API, API_KEY } from '../../constants';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import HelpIcon from '@material-ui/icons/Help';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Slide from '@material-ui/core/Slide';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DoneIcon from '@material-ui/icons/Done';

import UserDialog from '../../components/UserDialog';

import { connect } from 'react-redux';
import { fetchCustomer } from '../../actions/fetchCustomer';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class MainList extends Component {

  constructor(props) {
    super();
    this.routeParam = props.match.params.pantalla;
    this.type = this.routeParam.charAt(0).toUpperCase();
    
    this.state = {
      selectedDate: new Date(),
      currentMonth:new Date().getMonth(),
      openDialog:false,
      openModal:false,
      openFaltas:false,
      openAdminFaltas:false,
      openSnackBar:false,
      openOption:false,
      userOption:0,
      sessionOption:0,
      user :'',
      done: false,
      loading: -1,
      sessions: [],
      usersSessions:[],
      waitSessions:[],
      items:[],
      message:'',
      openDialogUsers:false,
      openDialogEdit:false,
      openMessage:false,
      notification:[],
      userBooking:0,
      userAsiste:0,
      userFaltas:0,
      userFree:0,
      leyendaOn: false,
      sessionUpdate:0,
      sessionUpdateDate:'',
      pasalistaSessions:'',
      usersFaltas:[],
      totalFaltas:'',
      usersAdminFaltas:[],
      sessionInfo:[],
      infoListGroups:[],
      totalAdminFaltas:'',
      userName:null,
      labelSaveName:true,
      sessionDay:'',
      sessionText:'',
      sessionLabel:'',
      sessionDate:'',
      editActionmessage:''
    }
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
    

     // si no hay token, redirecciona a login
    if(!user)
      this.props.history.push('/');

    this.apiFectchCustomer();
    this.setState({ user: JSON.parse(user)});
    //this.apiUserFaltas();
    this.apiUserAdminFaltas();
    this.apiFetchMessage();

  }

  async apiFectchCustomer(date = '') {
    var user = localStorage.getItem('user');
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;


        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }


        var select_date = '';
        if(date === ''){
          select_date = new Date(this.state.selectedDate);
        }else{
          select_date = new Date(date);
        }
        
        var month = select_date.getMonth() + 1;
        
        let date_from = month + '/' + select_date.getDate() + '/' + select_date.getFullYear();
        console.log(date_from);
        axios.get( URL_API + '/groupsbydate',
          {
              params: {
                 secure_key:API_KEY,
                 type:this.type,
                 user_id:id,
                 from: date_from, 
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ done: true});
          this.setState({ loading: false});
          this.setState({ sessions: response.data});
        })
        .catch(error => {
            console.log(error.response);
        });
    }
  }

  async apiFetchMessage(date = '') {
    var user = localStorage.getItem('user');
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;


        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }

        axios.get( URL_API + '/user/messages/',
          {
              params: {
                 secure_key:API_KEY,
                 user_id:id,
              }
          })
        .then(response => { 
          console.log(response.data);
            if(response.data.message){
              this.setState({ notification: response.data});
              this.setState({ openMessage: true});
            }

        })
        .catch(error => {
            console.log(error.response);
        });
    }
  }

  //Soltamos Plaza
  async apiDeleteMessage(message) {
    var user = localStorage.getItem('user');
    
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;
        
        axios.post( URL_API + '/user/messages/', {
          secure_key: API_KEY,
          user_id: id,
          message_id: message
        })
        .then(response => { 

        })
        .catch(error => {
            console.log(error.response);

        });
    }
  }

  //Cogemos Plaza
  async apiAddSession(session_id,session_date) {
    var user = localStorage.getItem('user');
    
    if(user){
        let currentUser = JSON.parse(user);
        var id = currentUser.ID;

        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }

        var g_level = currentUser.group_level;
        console.log(g_level);      
        axios.post(URL_API + '/user/sessionadd', {
          secure_key: API_KEY,
          user_id: id,
          group_level:g_level,
          type:this.type,
          from:session_date,
          session_id:session_id,
        })
        .then(response => {
        console.log(response.data); 
          if(response.data.session > 0){
              if(response.data.wait){
                this.setState({ message: response.data.message});
              }else{
                this.setState({ message: response.data.message});
              }
            this.handleOpenSnackBar();                
            
            this.apiFectchCustomer();
          }else if(response.data.session === 0){
            this.setState({ message: response.data.message});
            this.handleOpenSnackBar();
            this.setState({ loading: -1});
          }else{
            this.setState({ message: response.data.message});
            this.handleOpenSnackBar();
            this.setState({ loading: -1});
          }
          
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
            this.setState({ loading: -1});
        });
    }
  }

  //Soltamos Plaza
  async apiDelSession(session_id) {
    var user = localStorage.getItem('user');
    
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;

        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }
        
        axios.post( URL_API + '/user/sessiondelete', {
          secure_key: API_KEY,
          user_id: id,
          session_id:session_id
        })
        .then(response => { 
          if(response.data.session >= 0){
            console.log(response.data);
            this.setState({ message: response.data.message});
            this.handleOpenSnackBar();
            this.setState({ loading: -1});                
            this.apiFectchCustomer();
          }else{
            this.setState({ message: response.data.message});
            this.handleOpenSnackBar();
            this.setState({ loading: -1});
          }
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
            this.setState({ loading: -1});
        });
    }
  }

  //Soltamos Plaza de espera
  async apiDelSessionWait(session_id) {
    var user = localStorage.getItem('user');
    
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;

        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }

        axios.post( URL_API + '/user/sessiondeletewait', {
          secure_key: API_KEY,
          user_id: id,
          session_id:session_id
        })
        .then(response => { 
            this.setState({ message: response.data.message});
            this.handleOpenSnackBar();
            this.setState({ loading: -1});
            this.apiFectchCustomer();
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
            this.setState({ loading: -1});
        });
    }
  }
  //BUSCAMOS LA SESIONES DEL USUARIO POR MES (mostrar calendario)
  async apiFectchSessions(month = '') {
    var user = localStorage.getItem('user');

    if(user){
        var currentUser = JSON.parse(user);
        var id = currentUser.ID;

        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }

        var select_date = new Date();
        if(month === ''){
          month = select_date.getMonth() + 1;
        }else {
          month = parseInt(month) + 1;
        }
    
        var date_from = month + '/' + select_date.getDate() + '/' + select_date.getFullYear();
        console.log(id);
        axios.get( URL_API + '/user/sessions',
          {
              params: {
                 secure_key:API_KEY,
                 user_id:id,
                 from: date_from, 
              }
          })
        .then(response => { 
          console.log('SESISIONS');
          console.log(response.data);
          this.setState({ done: true});
          this.setState({ loading: false});
          this.setState({ items: response.data});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });
    }
  }

  //BUSCAMOS LOS USUARIOS QUE HAN FALTADO ESTA SEMANA
  async apiUserAdminFaltas(date = '') {

        var select_date = '';
        if(date === ''){
          select_date = new Date(this.state.selectedDate);
        }else{
          select_date = new Date(date);
        }
        
        var month = select_date.getMonth() + 1;
        
        let date_from = month + '/' + select_date.getDate() + '/' + select_date.getFullYear();
        console.log(date_from);
        
        axios.get( URL_API + '/admin/faltasv2',
          {
              params: {
                 secure_key:API_KEY,
                 type:this.type,
                 from: date_from, 
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ usersAdminFaltas: response.data.users});
          this.setState({ totalAdminFaltas: response.data.total});
          this.setState({ done: true});
        })
        .catch(error => {
            console.log(error.response);
            this.setState({ done: true});
        });
  }


  //BUSCAMOS LOS USUARIO DE CADA SESION ACTIVOS Y EN ESPERA (ADMINISTRADORES)
  async apiFectchsUsersBooking(month = '') {
    var user = localStorage.getItem('user');
    if(user){
        var currentUser = JSON.parse(user);
        var id = currentUser.ID;

        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }

        var select_date = new Date();
        if(month === ''){
          month = select_date.getMonth() + 1;
        }else {
          month = parseInt(month) + 1;
        }
    
        var date_from = month + '/' + select_date.getDate() + '/' + select_date.getFullYear();
      axios.get( URL_API + '/user/booking',
        {
            params: {
                 secure_key:API_KEY,
                 user_id:id,
                 from: date_from,
            }
        })
      .then(response => { 
         console.log(response.data);
        this.setState({ userBooking: response.data.booking});
         this.setState({ userAsiste: response.data.asiste});
        this.setState({ userFaltas: response.data.faltas});
        this.setState({ userFree: response.data.free});
      })
      .catch(error => {
          console.log(error.response);
    
      });

    }
    
  }

  //BUSCAMOS PLAZAS DE USUARIO TOTAL, FALTAS, LIBRES
  async apiFectchsUsersSession(session = '') {
    
      axios.get( URL_API + '/session/users',
        {
            params: {
               secure_key:API_KEY,
               session_id:session, 
            }
        })
      .then(response => { 
        console.log(response.data);
        this.setState({ pasalistaSessions: response.data.pasalista});
        this.setState({ usersSessions: response.data.users});
        this.setState({ waitSessions: response.data.wait});
      })
      .catch(error => {
          console.log(error.response);
    
      });
    
  }

  async apiFectchsUserGo(id,session_id,falta) {

        axios.post( URL_API + '/user/sessiongo', {
          secure_key: API_KEY,
          user_id: id,
          session_id:session_id,
          go:falta
        })
        .then(response => { 

         this.setState({ usersSessions: response.data.users});
         this.setState({ waitSessions: response.data.wait});

        })
        .catch(error => {
            console.log(error.response);
            
           
        });
    
  }

  async apiSessionRevise() {
    
    var user = localStorage.getItem('user');
    if(user){
        var currentUser = JSON.parse(user);
        var id = currentUser.ID;
        var nicename = currentUser.user_login;
        var session_id = this.state.sessionUpdate;
        axios.post( URL_API + '/admin/session/revise', {
          secure_key: API_KEY,
          user_id: id,
          session_id:session_id,
          user_login:nicename,
        })
        .then(response => { 

          console.log(response.data)

        })
        .catch(error => {
            console.log(error.response);
            
        });
    }
  }

  async apiSessionUpdateState() {
    
    var user = localStorage.getItem('user');
    if(user){
        var currentUser = JSON.parse(user);
        var id = currentUser.ID;
        var nicename = currentUser.user_login;
        var session_id = this.state.sessionUpdate;
        axios.post( URL_API + '/admin/session/state', {
          secure_key: API_KEY,
          user_id: id,
          session_id:session_id,
          state:0,
          user_login:nicename,
        })
        .then(response => { 

          console.log(response.data)

        })
        .catch(error => {
            console.log(error.response);
            
        });
    }
  }


  async setUsernameSession(user,session,name) {
    
        axios.post( URL_API + '/admin/user/name', {
          secure_key: API_KEY,
          user_id: user,
          session_id:session,
          name:name,
        })
        .then(response => { 
          this.setState({labelSaveName:true});
          

        })
        .catch(error => {
            console.log(error.response);
            
        });
  }

  //BUSCAMOS LA INFORMACIÓN DEL DÍA ELEGIDO
  async setSessionMainInfo() {

        axios.post( URL_API + '/admin/session/info', {
          secure_key: API_KEY,
          type:this.type,
          sessionday:this.state.sessionDay, 
          sessiontext:this.state.sessionText,
        })
        .then(response => { 
          this.setState({editActionmessage:response.data.message});
          console.log(response.data);

        })
        .catch(error => {
            console.log(error.response);
            
        });
    
  }

  //Desactivamos session main
  async setSessionMainState() {

        axios.post( URL_API + '/admin/session/main/state', {
          secure_key: API_KEY,
          type:this.type,
          sessionday:this.state.sessionDay, 
          state:0,
        })
        .then(response => { 
          this.setState({editActionmessage:response.data.message});
          console.log(response.data);

        })
        .catch(error => {
            console.log(error.response);
            
        });
    
  }

  handleOpenSnackBar () {
    this.setState({ openSnackBar: true});
  };

  render() {

  const handleChangeMonth = (event) => {
    
    this.setState({ currentMonth: event.target.value });
    this.apiFectchSessions(event.target.value);
    this.apiFectchsUsersBooking(event.target.value);
  };

  const handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    this.setState({ done: false });
    this.apiFectchCustomer(date);
    //this.apiUserFaltas(date);
    this.apiUserAdminFaltas(date);
  };

  const handleChange = (event) => {

    let session_data = event.target.name.split("_");

    this.setState({ loading: session_data[1]});
    if(event.target.checked){
      this.apiAddSession(session_data[1],session_data[2]);
    }else{
      this.setState({openDialog: true});
    }
  };

  const handlecClickWait = (event, reason) => {
    let session_data = event.target.parentNode.id.split("_");

    this.setState({ loading: session_data[1]});
    this.apiDelSessionWait(session_data[1]);
      
  };

  const handleAceeptDialog = (event, reason) => {
    this.apiDelSession(this.state.loading);
    this.setState({ openDialog: false});
  };

  const handleCloseDialog = (event, reason) => {
      this.setState({ openDialog: false});
      this.setState({ loading: false});
  };


  const handleCloseSnackBar = (event, reason) => {
    this.setState({ openSnackBar: false});
  };

  const handlecClickHoy = (event) => {
    var date = new Date();
    this.setState({ selectedDate: date });
    this.setState({ done: false });
    this.apiFectchCustomer(date);
    //this.apiUserFaltas(date);
    this.apiUserAdminFaltas(date);
  };

  const handleOpenModal = (event, reason) => {
      this.apiFectchSessions(this.state.currentMonth);
      this.apiFectchsUsersBooking(this.state.currentMonth);
      this.setState({ openModal: true});
  };

  const handleCloseModal = (event, reason) => {
      this.setState({ openModal: false});
      
  };
 
  const disableWeekends = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const handleClickView = (event)=> {
     let session_split = event.target.offsetParent.id.split("-");
     let session_date = session_split[2].split("/");
     let update_date = session_date[1] + '/' + session_date[0] + '/' + session_date[2];
      this.setState({ sessionUpdate: session_split[1]});
      this.setState({ sessionUpdateDate: update_date + ' ' + session_split[3]})
    this.apiFectchsUsersSession(session_split[1]);
    this.setState({ openDialogUsers: true});
    this.setState({userName: null});
  }

  const handleCloseDialogUsers = (event, reason) => {
      this.setState({ openDialogUsers: false});

  };

  const handleAcceptDialogUsers = (event, reason) => {
      this.setState({ openDialogUsers: false});
      this.apiSessionRevise();

  };

  const handleClickDesactiveSession = (event, reason) => {
      this.setState({ openDialogUsers: false});
      this.apiSessionRevise();

  };

  const handleClickPrev = (event, reason) => {
    var currentDate = this.state.selectedDate;
    var fecha = new Date(currentDate);
    fecha.setDate(fecha.getDate() - 7);

    this.setState({ selectedDate: fecha });
    this.setState({ done: false });
    this.apiFectchCustomer(fecha);
    //this.apiUserFaltas(fecha);
    this.apiUserAdminFaltas(fecha);   
  };

  const handleClickNext = (event, reason) => {
    var currentDate = this.state.selectedDate;
    var fecha = new Date(currentDate);
    fecha.setDate(fecha.getDate() + 7);

    this.setState({ selectedDate: fecha });
    this.setState({ done: false });
    this.apiFectchCustomer(fecha);
    //this.apiUserFaltas(fecha);
    this.apiUserAdminFaltas(fecha);
  };

  const handleClose = () => {
    this.setState({ openMessage: false});
  };

  const handleLeido = () => {
    this.apiDeleteMessage(this.state.notification.id_notification);
    this.setState({ openMessage: false});
  };

  const handleSessionGo = (e)=> {
    let go_split = e.target.id.split("_");
    const isChecked = e.target.checked;
    let go = isChecked ? 1 : 0;
    this.apiFectchsUserGo(go_split[1],go_split[2],go);

  }

  const addActiveLeyenda = (e) =>{
      console.log(e);
     
      if(this.state.leyendaOn === true) { 
          this.setState({leyendaOn: false});
      } else {
          this.setState({leyendaOn: true})
     }
  }

  const handleOpenAdminFaltas = (event, reason) => {
   
    this.setState({ openAdminFaltas: true});
  };

  const handleCloseAdminFaltas = (event, reason) => {
      this.setState({ openAdminFaltas: false});
      
  };  

  const handleChangeName = (e) => {
  		let user_split = e.target.id.split("_");
  		var new_userSessions = this.state.usersSessions;
  		new_userSessions[user_split[2]].user_name = e.target.value;
  		this.setState({usersSessions: new_userSessions});
  };

  const handleChangeText = (e) => {

      this.setState({sessionText:  e.target.value});
  };

  const handleOpenOption = (e) => {
      var user_split = e.target.id.split("-");
      if(user_split && user_split[1]){
        this.setState({ userOption: user_split[1]});
        this.setState({ sessionOption: user_split[2]});
        this.setState({ openOption: true});       
      }

  };  

  const handleCloseOption = (e) => {
    
   this.setState({ openOption: false});
  }; 

  const handleOpenDialodEdit = (event) => {
     
      let day_split = event.target.id.split("_");
      this.setState({ sessionDay: day_split[1]});
      this.setState({ sessionLabel: day_split[2]});
      this.setState({ sessionDate: day_split[3]});
      this.setState({sessionText: ''});
      this.setState({editActionmessage: ''});
      this.setState({ openDialogEdit: true});

  };

  const handleCloseDialogEdit = (event, reason) => {
      this.setState({ openDialogEdit: false});

  };

  const handlecClickSessionText = (event ) => {

      this.setSessionMainInfo();
  }

  const handlecClickSessionMainState = (event) => {
     this.setSessionMainState();
  }

	const handleSaveName = (e) => {
    this.setState({labelSaveName:false})
		let user_split = e.target.id.split("_");
		var username = this.state.usersSessions[user_split[2]].user_name;
		var session_id = user_split[1];
		var user_id = user_split[3];
		this.setUsernameSession(user_id,session_id,username);
	}

  var buttonAdminFaltas;
  if (this.state.user && this.state.user.level === '10') {
      buttonAdminFaltas = <Button variant="contained" color="secondary" disableElevation onClick={handleOpenAdminFaltas}>
                  {this.state.totalAdminFaltas}
              </Button>  
  }

  const groups = this.state.sessions;

  const infoListGroups = this.state.infoListGroups;

  return (
    
      <Grid id={`session${this.routeParam}`} container justify="center">

        <Grid item xs={12} sm={12} md={12}>
          <div className={`header-section header-section__${this.routeParam}`}>{this.routeParam}</div>
        </Grid>
        <Grid   container
              direction="row"
              justify="center"

              alignItems="center">
          <Grid item xs={12} className="topactions">
           <Button id="prev" onClick={handleClickPrev} color="primary" variant="text" size="small"  disabled={ this.state.selectedDate.getMonth() < this.state.currentMonth ? true : false}>
              <ArrowLeftIcon fontSize="large" />
            </Button>
           
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                shouldDisableDate={disableWeekends}
                  format="dd/MM/yyyy"
                  value={this.state.selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
            </MuiPickersUtilsProvider>
            
           <Button id="goToday" onClick={handlecClickHoy} variant="text">
              Hoy
            </Button> 
            
           <Button id="historial" onClick={handleOpenModal} variant="text">
              Reservas
            </Button> 
           
           <Button  id="next" onClick={handleClickNext} color="primary" variant="text" size="small">
            <ArrowRightIcon fontSize="large" />
           </Button >
          </Grid>
        </Grid>
      
        {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        <Grid item xs={12} sm={12} md={6}>

          {groups.map((session, indx) => {
            var buttonAdminEdit = <span>{session.date}</span>;
            if (this.state.user && this.state.user.level === '10') {
                buttonAdminEdit = <span id={`id_${session.day_next}_${session.description}_${session.date}`} onClick={handleOpenDialodEdit}>{session.date}</span>  
            }
            return(         
                    <List key={indx} className="userList" dense>
                    <div className="titleList">{buttonAdminEdit}  <span>{session.description}</span></div>
                      {session.hours.map((value, idx) => {
                       
                        var free = value.free;
                        var wait = value.wait;
                        var fgroup = new Date(value.date);
                        var ftoday = new Date();
                        fgroup.setHours(0,0,0,0);
                        ftoday.setHours(0,0,0,0);
                        var classWait = 'waitCheck';
                        if(value.wuser > 0 ){
                          classWait = 'active';
                         
                        }else if ( wait > 0 && value.wuser <= 0){
                           classWait = 'subactive';
                        }

                        if(this.state.user.level === '10' && value.wait_real > 0){
                          classWait = 'subactive';
                        }

                        if(free <= '0'){
                          return (
                            <ListItem key={idx} dense >
                              <ListItemIcon>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={<RadioButtonUncheckedRoundedIcon />} 
                                      checkedIcon={<CheckCircleRoundedIcon />}
                                      name= {`nm_${value.group}_${value.date}`}
                                      id= {`id-${idx}`}
                                      disabled = {fgroup < ftoday  ? true : false}
                                      checked={Boolean(Number(value.user))}
                                      onChange={handleChange}
                                      inputProps={{ 'aria-label': 'secondary checkbox', 'size': 'large' }}
                                    />
                                  }
                                  label={ value.label }
                                />
                              </ListItemIcon>

                            
                              <CircularProgress color="primary" 
                                className={this.state.loading === value.group ? '' : 'nodisplay'} 
   
                              />
                              <ListItemSecondaryAction>
                                  <Chip size="small" 
                                  avatar={<Avatar>{this.state.user.level === '10' ? value.wait_real : value.wait}</Avatar>} 
                                  label="Espera" 
                                  id= {`ch_${value.group}_${value.date}`}
                                  className={ classWait  }  
                                  onClick={handlecClickWait}
                                  disabled = {value.wuser > 0 ? false : true}
                                  /> 

                                  <Button size="small" 
                                  id={`ni-${value.group}-${value.date}-${value.label}`} 
                                  onClick={handleClickView} 
                                  className={this.state.user.level === '10' ? '' : 'nodisplay'} >ver</Button>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        }else if(free > 0 && wait > 0){

                          return (
                            <ListItem key={idx}  dense button>
                              <ListItemIcon>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={<RadioButtonUncheckedRoundedIcon />} 
                                      checkedIcon={<CheckCircleRoundedIcon />}
                                      name= {`nm_${value.group}_${value.date}`}
                                      id= {`id-${idx}`}
                                      disabled = {fgroup < ftoday  ? true : false}
                                      checked={Boolean(Number(value.user))}
                                      onChange={handleChange}
                                      inputProps={{ 'aria-label': 'secondary checkbox', 'size': 'large' }}
                                    />
                                  }
                                  label={ value.label }
                                />
                              </ListItemIcon>
                             
                              <CircularProgress color="primary" 
                                className={this.state.loading === value.group ? '' : 'nodisplay'} 
   
                              />

                              <ListItemSecondaryAction>

                                  <Chip size="small" avatar={<Avatar>{free}</Avatar>} classes={{ root: 'freeSession' }} label="L" /> 
                                  <Chip size="small" 
                                  avatar={<Avatar>{this.state.user.level === '10' ? value.wait_real : value.wait}</Avatar>} 
                                  label="E" 
                                  id= {`ch_${value.group}_${value.date}`}
                                  className={ classWait  }  
                                  onClick={handlecClickWait}
                                  disabled = {value.wuser > 0 ? false : true}
                                  />  

                                  <Button size="small" 
                                  id={`ni-${value.group}-${value.date}-${value.label}`}  
                                  onClick={handleClickView} 
                                  className={this.state.user.level === '10' ? '' : 'nodisplay'} >ver</Button>                              
                              </ListItemSecondaryAction>
                            </ListItem>
                          );                
                        }else{
                           
                          return (
                            <ListItem key={idx}  dense button>
                              <ListItemIcon>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={<RadioButtonUncheckedRoundedIcon />} 
                                      checkedIcon={<CheckCircleRoundedIcon />}
                                      name= {`nm_${value.group}_${value.date}`}
                                      id= {`id-${idx}`}
                                      disabled = {fgroup < ftoday  ? true : false}
                                      checked={Boolean(Number(value.user))}
                                      onChange={handleChange}
                                      inputProps={{ 'aria-label': 'secondary checkbox', 'size': 'large' }}
                                    />
                                  }
                                  label={ value.label }
                                />

                              </ListItemIcon>
                          
                              <CircularProgress color="primary" 
                                className={this.state.loading === value.group ? '' : 'nodisplay'} 
   
                              />
                              <ListItemSecondaryAction>

                                  <Chip size="small" avatar={<Avatar>{free}</Avatar>} classes={{ root: 'freeSession' }} label="Libres" /> 
                                  
                                  <Button size="small" 
                                  id={`ni-${value.group}-${value.date}-${value.label}`}  
                                  onClick={handleClickView} 
                                  className={this.state.user.level === '10' ? '' : 'nodisplay'} >ver</Button>
                              </ListItemSecondaryAction>

                            </ListItem>
                          ); 

                        }
                      })}
                    </List>
            );
          })}
            <Grid item xs={12} sm={12} md={12}>
              {buttonAdminFaltas}
            </Grid>
        </Grid>

        )}

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.openSnackBar}
            autoHideDuration={4000}
            onClose={handleCloseSnackBar}
            message={this.state.message}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
           />

      <Dialog
        id="alert-dialog"
        open={this.state.openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h5" component="h5" align="center">
                <HelpIcon className="helpalert" style={{fontSize:30}} color="primary"/>
            </Typography>
            <Typography variant="h6" align="center" color="textPrimary">
              vas dejar la plaza libre
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAceeptDialog} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog id="fulldialog" fullScreen open={this.state.openModal} onClose={handleCloseModal} >
        <DialogContent className="fulldialog_content">
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12}>
              <div className="header-section header-section__funcional">MIS SESIONES</div>
            </Grid>
            <Grid   
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className="top-reservas"
                  >
              <Grid item xs={6}>
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentMonth}
                  onChange={handleChangeMonth}
                >
                  <MenuItem value={0}>Enero</MenuItem>
                  <MenuItem value={1}>Febrero</MenuItem>
                  <MenuItem value={2}>Marzo</MenuItem>
                  <MenuItem value={3}>Abril</MenuItem>
                  <MenuItem value={4}>Mayo</MenuItem>
                  <MenuItem value={5}>Junio</MenuItem>
                  <MenuItem value={6}>Julio</MenuItem>
                  <MenuItem value={7}>Agosto</MenuItem>
                  <MenuItem value={8}>Septiembre</MenuItem>
                  <MenuItem value={9}>Octubre</MenuItem>
                  <MenuItem value={10}>Noviembre</MenuItem>
                  <MenuItem value={11}>Diciembre</MenuItem>
                </Select> 
              </Grid>
              <Grid item xs={1}>
               <Avatar className="infavatar inftotal" onClick={addActiveLeyenda}>{this.state.userBooking}</Avatar>
              </Grid>
              <Grid item xs={1}>
              &nbsp;=&nbsp;
              </Grid>
              <Grid item xs={1}>
               <Avatar className="infavatar infasiste" onClick={addActiveLeyenda}>{this.state.userAsiste}</Avatar>
              </Grid>
              <Grid item xs={1}>
              <Avatar className="infavatar inffaltas" onClick={addActiveLeyenda}>{this.state.userFaltas}</Avatar>
              </Grid>
              <Grid item xs={1}>
              <Avatar className="infavatar inflibres" onClick={addActiveLeyenda}>{this.state.userFree}</Avatar>
              </Grid>
            </Grid>
            <Grid item xs={12}>
                    <div id="leyenda" className={this.state.leyendaOn ? 'leyendaon': ''}>
                     
                        <Avatar className="infavatar inftotal">{this.state.userBooking}</Avatar>
                        <Typography variant="caption" align="center">Total plazas</Typography>
                        <Avatar className="infavatar infasiste">{this.state.userAsiste}</Avatar>
                        <Typography variant="caption" align="center">Asistencia</Typography>
                        <Avatar className="infavatar inffaltas">{this.state.userFaltas}</Avatar>
                        <Typography variant="caption" align="center">Faltas</Typography>
                        <Avatar className="infavatar inflibres">{this.state.userFree}</Avatar>
                        <Typography variant="caption" align="center">Libres</Typography>
                    
                    </div>              
            </Grid>
            <Grid item xs={12}>
              <Grid container
                  direction="row"
                  className="carditem"
              >
                {this.state.items.map((item, indx) => {
                  if(item.type) {  
                var hours_list = item.hour.map((hour,i) => {

                    return (<Chip size="small" className={`grouptype_${hour.hour_type}`} label={hour.hour_label} />);
                  
                });

                  return( 
                    <Card  variant="outlined" className={`group_${item.assigned}`} key={indx}>
                      <CardContent>
                        <Typography variant="caption" display="block" align="center">
                          {item.day} {item.label}
                        </Typography>
                           {hours_list}
                      </CardContent>
                    </Card>
                  );
                  }
                })}
                </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog id="fulldialogUsers"  open={this.state.openDialogUsers} onClose={handleCloseDialogUsers}         
        fullWidth={true}
       
      >
      <DialogTitle id="single-dialog-title">Clases {this.state.sessionUpdateDate}</DialogTitle>
        <DialogContent className="fulldialog_content">
          <List>

                {this.state.usersSessions.map((item, indx) => {
                    var falta = false;
                    var userAlert='userAlert_0';
                      if(item.falta == 1){
                        falta = true;
                      }
                      if(item.alert == 1){
                        userAlert = 'userAlert_1';
                      }
                      	var input_name = '';
                      	var buttonSave='';
                      
                     if(item.ID == 1 || item.ID == 26 || item.ID == 119 || item.ID == 53){
                     	
                     	input_name =<InputBase
							                 className="userName"
							                  id={`name_${item.id_session}_${indx}`}
							                  
							                  value={item.user_name}							              
							                  onChange={handleChangeName}
							                  inputProps={{ 'aria-label': 'buscar...' }}
							                />

							        var label = this.state.labelSaveName ? 'GD' : '...';    
                     	buttonSave = <span className="btnsave" onClick={handleSaveName}  id={`uname_${item.id_session}_${indx}_${item.ID}`}> 
            							  {label}
          							</span> 							                
                     }
                  return( 
          <ListItem key={indx} dense  >

            <ListItemText className={userAlert} primary={item.display_name}  secondary={input_name} />
            	
                  <ListItemSecondaryAction className="buttonAction">
                  {buttonSave}
              <Checkbox
                id= {`check_${item.ID}_${item.id_session}`}
                edge="end"
                value={item.falta}
                onClick={handleSessionGo}
                checked={ falta }
                inputProps={{ 'aria-labelledby': 'falta' }}
              />

                    <IconButton edge="end" aria-label="edit">
                      <MoreVertIcon id={`nuser-${item.ID}-${item.id_session}`} onClick={handleOpenOption} />
                    </IconButton>

                  </ListItemSecondaryAction>
          </ListItem>
                  );
  
                })}

                {this.state.waitSessions.map((item, indx) => {
                 
                  return( 
          <ListItem key={indx} className="list-await">
            <ListItemAvatar>
              <Avatar>
                <PersonIcon size="small"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.display_name} />
          </ListItem>
                  );
  
                })}
          </List>
          <Typography variant="caption" display="block"  color="secondary" gutterBottom>
            {this.state.pasalistaSessions}
          </Typography>
 
        </DialogContent>

        <DialogActions>
           <Button onClick={handleClickDesactiveSession} color="secondary">
            Desactivar
          </Button>         
           <Button onClick={handleCloseDialogUsers} color="primary">
            Cancelar
          </Button>     
          <Button onClick={handleAcceptDialogUsers} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={this.state.openMessage}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           <div dangerouslySetInnerHTML={{__html: this.state.notification.message}}></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
          <Button onClick={handleLeido} color="primary">
            Leído
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={this.state.openAdminFaltas}
        className="dialogFaltas"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAdminFaltas}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="fulldialog_content">
          <List dense>
                {this.state.usersAdminFaltas.map((item, indx) => {

                  return( 
          <ListItem key={indx} dense button>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon size="small"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${item.firstname} ${item.lastname}`} />

          </ListItem>
                  );
  
                })}
          </List>
 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdminFaltas} color="primary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog id="opyiondialog" fullScreen open={this.state.openOption} onClose={handleCloseOption} >
        <DialogContent>
          <UserDialog idUser={this.state.userOption} idSession={this.state.sessionOption} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOption} color="primary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

     
      <Dialog
        open={this.state.openDialogEdit}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialogEdit}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogContent>
          <h3>{this.state.sessionDate}</h3>
         <h4>{this.state.sessionLabel}</h4>
            <Grid   
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  >
              <Grid item xs={7}>
               

                <InputBase
                  className="username" 
                  id="sessionText"                     
                  value={this.state.sessionText}                            
                  onChange={handleChangeText}                        
                />

              </Grid>
              <Grid item xs={5}>
              <label>&nbsp;</label>
                <Button onClick={handlecClickSessionText} color="default">
                  Aceptar
                </Button>
              </Grid>

              <Grid item xs={7}>
               
                 <label>Desactivar sesiones</label>

              </Grid>
              <Grid item xs={5}>
             
                <Button onClick={handlecClickSessionMainState} color="default">
                  Desactivar
                </Button>
              </Grid>
            </Grid>
          <p>{this.state.editActionmessage}</p>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogEdit} color="primary">
            Cerrar
          </Button>

        </DialogActions>
      </Dialog>

      </Grid>


    ); //return
  } //render
}


MainList.propTypes = {

}

MainList.defaultProps = {
  sessions: []
};

const mapStateToProps = state => ({
  sessions: state.sessions
});
export default withRouter(connect(mapStateToProps,{fetchCustomer})(MainList));  